<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="挂靠审核" />
    </div>
    <div class="panel_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="rowClick"
        v-loading="loading">
        <el-table-column
          label="序号"
          type="index"
          :index="indexMethod">
        </el-table-column>
        <el-table-column
          prop="username"
          label="姓名">
        </el-table-column>
        <el-table-column
          prop="mobile"
          label="账号">
        </el-table-column>
        <el-table-column
          prop="contactEmail"
          label="邮箱">
        </el-table-column>
        <el-table-column
          prop="gender"
          label="性别">
          <template slot-scope="scope">
            {{ { 0: '未知', 1: '男', 2: '女' }[scope.row.gender] }}
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="panel_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'

export default {
  data () {
    return {
      loading: false,
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      tableData: []
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    indexMethod (index) {
      return this.pagination.pageSize * (this.pagination.pageNum - 1) + index + 1
    },
    rowClick (row, column, event) {
      const { href } = this.$router.resolve({
        path: `/member-detail/${row.id}`
      })

      window.open(href, '_blank')
    },
    getTableData (pageNum) {
      this.loading = true
      api.getOrgGkAuditList({
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    }
  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
